import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DragulaModule } from 'ng2-dragula';

import { GridComponent, GridHeaderWrapperComponent, PaginationComponent, StepperComponent, ExamItemComponent, ExamItemResultComponent } from './components';
import { ClickOutsideDirective, EmailValidatorDirective } from './directives';
import { DurationFormatPipe, ExcludePipe, OneDecimalPlacePipe, ReadablePipe, SafePipe } from './pipes';
import { CountDownService, StateService } from './services';

import { VExamItemMultipleAnswerComponent } from './components/exam-item/v-exam-item-multiple-answer/v-exam-item-multiple-answer.component';
import { VExamItemMultipleChoiceComponent } from './components/exam-item/v-exam-item-multiple-choice/v-exam-item-multiple-choice.component';
import { VExamItemMultipleChoiceReasonComponent } from './components/exam-item/v-exam-item-multiple-choice-reason/v-exam-item-multiple-choice-reason.component';
import { VExamItemShortAnswerComponent } from './components/exam-item/v-exam-item-short-answer/v-exam-item-short-answer.component';
import { VExamItemSubjectiveComponent } from './components/exam-item/v-exam-item-subjective/v-exam-item-subjective.component';
import { VExamItemTabledMultipleAnswerComponent } from './components/exam-item/v-exam-item-tabled-multiple-answer/v-exam-item-tabled-multiple-answer.component';
import { VExamItemTabledMultipleQuestionComponent } from './components/exam-item/v-exam-item-tabled-multiple-question/v-exam-item-tabled-multiple-question.component';
import { VExamItemDragDropComponent } from './components/exam-item/v-exam-item-drag-drop/v-exam-item-drag-drop.component';


const components: any[] = [
  GridComponent,
  GridHeaderWrapperComponent,
  PaginationComponent,
  StepperComponent,
  ExamItemComponent,
  ExamItemResultComponent
];

const directives: any[] = [
  ClickOutsideDirective,
  EmailValidatorDirective,
];

const pipes: any[] = [
  DurationFormatPipe,
  ExcludePipe,
  OneDecimalPlacePipe,
  ReadablePipe,
  SafePipe
];

const services: any[] = [
  CountDownService,
  StateService
];

@NgModule({
  declarations: [
    VExamItemMultipleAnswerComponent,
    VExamItemMultipleChoiceComponent,
    VExamItemMultipleChoiceReasonComponent,
    VExamItemShortAnswerComponent,
    VExamItemSubjectiveComponent,
    VExamItemTabledMultipleAnswerComponent,
    VExamItemTabledMultipleQuestionComponent,
    VExamItemDragDropComponent,
    [...components],
    [...directives],
    [...pipes]
  ],
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    NgxDatatableModule,
    DragulaModule
  ],
  exports: [
    [...components],
    [...directives],
    [...pipes]
  ]
})
export class LibModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LibModule,
      providers: services
    };
  }
}
