/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DragDropQuestionDTO } from './dragDropQuestionDTO';
import { MultipleAnswerQuestionDTO } from './multipleAnswerQuestionDTO';
import { MultipleChoiceQuestionDTO } from './multipleChoiceQuestionDTO';
import { MultipleChoiceReasonQuestionDTO } from './multipleChoiceReasonQuestionDTO';
import { ShortAnswerQuestionDTO } from './shortAnswerQuestionDTO';
import { SubjectiveQuestionDTO } from './subjectiveQuestionDTO';
import { TabledMultipleAnswerQuestionDTO } from './tabledMultipleAnswerQuestionDTO';
import { TabledMultipleQuestionDTO } from './tabledMultipleQuestionDTO';

export interface QuestionDTO {
    id: string;
    order: number;
    questionType: QuestionDTO.QuestionTypeEnum;
    totalScore: number;
    score: number;
    partId: string;
    examTestResultId: string;
    multipleChoiceQuestion: MultipleChoiceQuestionDTO;
    shortAnswerQuestion: ShortAnswerQuestionDTO;
    subjectiveQuestion: SubjectiveQuestionDTO;
    tabledMultipleQuestion: TabledMultipleQuestionDTO;
    multipleAnswerQuestion: MultipleAnswerQuestionDTO;
    tabledMultipleAnswer: TabledMultipleAnswerQuestionDTO;
    multipleChoiceReasonQuestion: MultipleChoiceReasonQuestionDTO;
    dragDropQuestion: DragDropQuestionDTO;
}
export namespace QuestionDTO {
    export type QuestionTypeEnum = 'MultipleChoice' | 'ShortAnswer' | 'Subjective' | 'TabledMultipleChoice' | 'MultipleAnswer' | 'TabledMultipleAnswer' | 'MultipleChoiceReason' | 'DragDrop';
    export const QuestionTypeEnum = {
        MultipleChoice: 'MultipleChoice' as QuestionTypeEnum,
        ShortAnswer: 'ShortAnswer' as QuestionTypeEnum,
        Subjective: 'Subjective' as QuestionTypeEnum,
        TabledMultipleChoice: 'TabledMultipleChoice' as QuestionTypeEnum,
        MultipleAnswer: 'MultipleAnswer' as QuestionTypeEnum,
        TabledMultipleAnswer: 'TabledMultipleAnswer' as QuestionTypeEnum,
        MultipleChoiceReason: 'MultipleChoiceReason' as QuestionTypeEnum,
        DragDrop: 'DragDrop' as QuestionTypeEnum
    };
}
